/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('slick-carousel');

require('../../../../bower_components/jq-clipthru/build/jquery-ui-1.10.4.custom.min')
require('../../../../bower_components/jq-clipthru/build/jq-clipthru.js')

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const anime = require('animejs/lib/anime.js');
const app = new Vue({
  el: '#app',
  data() {
    return {
      scrollElement: window.document.scrollingElement || window.document.body || window.document.documentElement,
      formJobs: window.postedCandidate || false,
      formCompany: window.postedCompany || false,
      scrollPos: 0,
      posting: false,
      displayMenu: false,
      wHeight: window.outerHeight,
      opened: false
    }
  },
  watch: {
    'formCompany': function () {
      this.handleScroll()
    },
    'formJobs': function () {
      this.handleScroll()

    }
  },
  created() {
    _.forEach(document.querySelectorAll('.form'), form => {
      form.classList.add('d-flex')
      form.classList.remove('d-none')
    })
    _.forEach(document.querySelectorAll('.hidden-ready'), elt => {
      elt.classList.remove('d-none')
      elt.classList.add('d-flex')
    })

  },
  mounted() {

    if (window.outerWidth >= 768) {
      $('#clipthru').clipthru()
      $('#clipthru').on('collisionStart.jq-clipthru ', () => {
        this.$forceUpdate()
      })
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
    window.addEventListener("scroll", window._.throttle(this.onScroll, 100));
    $('.slick').slick();

    $('.slick-respo').slick({
      slidesToShow:5,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });

    window.AOS.init();
    var myTimeline = anime.timeline({
      easing: 'easeInOutQuad',
      duration: 750,
      delay: 1000
    });
    myTimeline.add({
      targets: '.splash h2 span:first-child',
      translateY: '-100%',
      opacity: 1,
    }).add({
      targets: '.splash h2 span:last-child',
      translateY: '100%',
      opacity: 1,
    }, 0).add({
      targets: '.splash h2 span:nth-child(2)',
      opacity: 1,
      delay: 0
    })
  },
  updated: function () {
    this.$nextTick(function () {
      let as = document.querySelectorAll('aside:not(.absolute) .links a')
      _.forEach(as, a => {
        a.removeEventListener('click', this.listener)
        a.addEventListener('click', this.listener)
      })
    })
  },
  methods: {
    listener: function (e) {
      e.preventDefault()
      let a = e.target
      let target = a.getAttribute('href')
      this.goto(target.replace("#", ''))
    },
    goto: function (slug) {
      let element = document.querySelector('.' + slug)
      anime({
        targets: this.scrollElement,
        scrollTop: element.offsetTop,
        duration: 500,
        easing: 'easeInOutQuad'
      });
    },
    onScroll() {
      var st =
        window.pageYOffset || this.scrollElement.scrollTop;
      if (st > this.scrollPos) {
        this.direction = "down";
      } else {
        this.direction = "up";
      }
      this.scrollPos = st <= 0 ? 0 : st; // For Mobile or negative scrolling
      let s = this.findActive();
      if (!s) {
        return
      }
      let as = document.querySelectorAll('aside .anchors a')
      _.forEach(as, a => {
        a.classList.remove('active')
      })
      let activeAnchor = `.link-${s.id}`
      _.forEach(document.querySelectorAll(activeAnchor), a => {
        a.classList.add('active')
      })
      if (s.id) {
        history.replaceState(null, null, "#" + s.id);
      } else {
        history.replaceState(null, null, "#");
      }
    },
    findActive() {
      let sections = [...document.querySelectorAll(".home-screen")];
      let s = _.find(sections, s => {
        var bounding = s.getBoundingClientRect();
        let top = Math.floor(bounding.top);
        let bottom = Math.floor(bounding.bottom);
        if (this.direction == "up") {
          return bottom > window.innerHeight / 2;
        } else {
          return top >= 0 || (top < 0 && bottom > window.innerHeight);
        }
      });
      /* if (!s) {
        if (this.direction == "up") {
          s = sections[0];
        } else {
          s = _.last(sections);
        }
      }*/
      return s;
    },
    afterMenuHidden() {
      this.opened = false
    },
    beforeMenuVisible() {
      this.opened = true
    },
    handleScroll() {
      if (this.formCompany) {
        document.body.classList.add('no-scroll')
        document.querySelector('html').classList.add('no-scroll')
      } else if (this.formJobs) {
        document.querySelector('html').classList.add('no-scroll')
        document.body.classList.add('no-scroll')
      } else {
        document.querySelector('html').classList.remove('no-scroll')
        document.body.classList.remove('no-scroll')
      }
    }
  }
});
